"use client"

import { AuthModalMode, Link, useAuthModal } from "@store-platform/ui/common"
import { SvgStoreLogoIcon } from "@store-platform/ui/icons"
import { SignInForm } from "./SignInForm"
import { SignUpForm } from "./SignUpForm"
import { ResetPasswordForm } from "./ResetPasswordForm"

type AuthFormProps = {
  mode: AuthModalMode | null
  redirectTo?: string
  navigation: "link" | "state"
}

export function AuthForm(props: AuthFormProps) {
  const { setMode, footer } = useAuthModal()
  const mode = props.mode

  if (!mode) return null

  const isLogin = mode === "sign-in"
  return (
    <div className="bg-white overflow-auto scrollbar-thin py-4 max-sm:px-1 sm:w-[475px] sm:rounded-lg sm:p-10 sm:shadow h-fit mt-6">
      <div className="sm:my-4 sm:mx-auto sm:w-full sm:max-w-md space-y-6 mb-4">
        <Link variant="naked" href="/">
          <SvgStoreLogoIcon className="mx-auto w-10 h-10" />
        </Link>
        <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900">
          {mode === "sign-in" && "Log in to your account"}
          {mode === "sign-up" && "Create an account"}
          {mode === "forgot-password" && "Password Reset"}
        </h2>
      </div>
      {mode === "sign-in" && <SignInForm redirectTo={props.redirectTo} />}
      {mode === "sign-up" && <SignUpForm />}
      {mode === "forgot-password" && <ResetPasswordForm />}

      {footer && (
        <>
          {mode !== "forgot-password" && (
            <Link
              className="flex justify-center w-full pt-5 text-gray-600"
              variant="naked"
              {...(props.navigation === "link"
                ? { href: "/auth/reset-password" }
                : {
                    href: "",
                    onClick: (e: any) => {
                      e.preventDefault()
                      setMode("forgot-password")
                    },
                  })}
            >
              Forgot password?
            </Link>
          )}
          <Link
            variant="naked"
            className="mb-2 w-full inline-flex justify-center"
            {...(props.navigation === "link"
              ? { href: isLogin ? "/auth/sign-up" : "/auth/sign-in" }
              : {
                  href: "",
                  onClick: () => setMode(isLogin ? "sign-up" : "sign-in"),
                })}
          >
            <p className="pt-5 text-center text-gray-500">
              {mode === "sign-in" && "Don't have an account yet?"}
              {mode === "sign-up" && "Already have an account?"}
              {mode === "forgot-password" && "Go Back to"}
            </p>
            <div>&nbsp;</div>
            <p className="pt-5 text-center text-blue-500">
              {isLogin ? "Sign up" : "Sign in"}
            </p>
          </Link>
          <div className="pt-5 text-center text-sm italic text-gray-500">
            <p>
              By clicking {isLogin ? "Sign in" : "Sign up"} above, I agree to
              Store.app&apos;s{" "}
            </p>
            <Link href="/terms" newTab variant="blue">
              TOS
            </Link>{" "}
            and{" "}
            <Link href="/privacy" newTab variant="blue">
              Privacy Policy
            </Link>
            .
          </div>
        </>
      )}
    </div>
  )
}
