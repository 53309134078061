import * as React from "react"
import type { SVGProps } from "react"
const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="currentColor"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M17.109 4.558a.625.625 0 0 1 0 .884l-9.167 9.167a.625.625 0 0 1-.884 0l-4.167-4.167a.625.625 0 1 1 .884-.884L7.5 13.283l8.725-8.725a.625.625 0 0 1 .884 0"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgCheck
