import type { SVGProps } from "react"
import * as React from "react"

const SvgStoreLogoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="#366EFF"
    viewBox="0 0 12 12"
    {...props}
  >
    <circle cx={2.679} cy={9.321} r={2.679} />
    <circle cx={2.679} cy={2.679} r={2.679} />
    <circle cx={9.321} cy={9.321} r={2.679} />
    <path
      fillRule="evenodd"
      d="M10.286 0H8.357v1.714H6.643v1.929h1.714v1.714h1.929V3.643H12V1.714h-1.714z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgStoreLogoIcon
