import { Icon, Listing, Review } from "./listing.types"
import { EnrichedList } from "./list.types"
import { DisplayLocationPattern } from "./users.types"

export type UserRole = "user" | "developer" | "admin"

export type Profile = {
  userId: string
  displayName: string
  username: string
  accountType: "user" | "developer" | "admin"
  profile: UserProfile
  listings: Listing[]
  reviews: Review[]
  lists: EnrichedList[]
  kycVerification: "not_started" | "verified"
  displayLocationOptions: ProfileDisplayLocationOption[]
}

export type ProfileSettings = {
  displayName: string
  username: string
  description?: string
  link?: string
  linkTwitter?: string
  linkLinkedin?: string
  displayLocationPattern?: DisplayLocationPattern
  displayLocationOptions: ProfileDisplayLocationOption[]
}

export type UserProfile = {
  displayName: string
  description?: string
  link?: string
  linkTwitter?: string
  linkLinkedin?: string
  pinnedReviewIds: number[]
  profilePicture?: string
  createdAt: string
  countRatings?: number
  avgRating?: number
  countFavs?: number
  displayLocation?: string
  displayLocationPattern?: DisplayLocationPattern
  country?: string
}

export type ProfileUpdateRequest = {
  displayName?: string
  description?: string
  link?: string
  linkTwitter?: string
  linkLinkedin?: string
  profilePicture?: File
  displayLocation?: string
  displayLocationPattern?: DisplayLocationPattern
}

export type ProfileDisplayLocationOption = {
  pattern: string
  value: string
}

export type Testimonial = {
  review: Review
  appName: string
  appIcons: Icon[]
  authorName: string
  authorUsername: string
  authorProfilePicture: string
  pinned: boolean
}

export const TwitterProfileUrlRegex =
  /^https:\/\/(twitter|x)\.com\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+)/

export const LinkedinProfileUrlRegex =
  /^https:\/\/www\.linkedin\.com\/(pub|in|profile|company)\/([-a-zA-Z0-9]+)\/*/

export const AppleAppStoreUrlRegex =
  /https:\/\/apps\.apple\.com\/[a-z]{2}\/app\/[^/]+\/id\d+/

export const GooglePlayStoreUrlRegex =
  /https:\/\/play\.google\.com\/store\/apps\/details(?:\/[\w\s]+)?\??(?:.*&)?id=[\w.]+/
