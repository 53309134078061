export const getPublicRootUrl = (): string => {
  switch (process.env["NEXT_PUBLIC_VERCEL_ENV"]) {
    case "production":
      return `https://${process.env["NEXT_PUBLIC_APP_URL"]}`
    case "preview":
      return `https://${process.env["NEXT_PUBLIC_VERCEL_BRANCH_URL"]}`
    default:
      // local development
      return `${process.env["NEXT_PUBLIC_APP_PROTOCOL"] || "https"}://${process.env["NEXT_PUBLIC_APP_URL"] || process.env["NEXT_PUBLIC_VERCEL_BRANCH_URL"]}`
  }
}
